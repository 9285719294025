import { useInfiniteQuery } from 'react-query'
import { Grid, Stack, useMediaQuery, useTheme } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { Box } from '@mui/system'
import { Fragment, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { getEventListByOrganization, IMonth, qk } from 'src/api'
import { useLocale } from 'src/lib/use-locale'
import { eventCardWithoutTagsSpacing } from 'src/layouts/spacing'
import { EventCardWithoutTags } from 'src/modules/event/card'
import { monthsContentMinHeight, MonthsTabs } from 'src/components/months-tabs'
import { organizationHeaderGradient } from 'src/assets/gradients'
import { useIsSessionLoaded } from 'src/lib/use-is-session-loaded'
import { NonEmptyArray } from 'fp-ts/lib/NonEmptyArray'

type Props = {
  organizationSlug: string
  months: NonEmptyArray<IMonth>
}

export const OrganizationEvents = ({ organizationSlug, months }: Props) => {
  const { t } = useTranslation()
  const isSessionLoaded = useIsSessionLoaded()
  const { locale } = useLocale()

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const [month, setMonth] = useState<IMonth>(months[0])

  const eventsArgs = {
    organizationSlug,
    limit: isDesktop ? 12 : 10,
    locale,
    month,
  }

  const $events = useInfiniteQuery(
    qk.cms.public.event.listByOrganization.toKeyWithArgs(eventsArgs),
    ({ pageParam }) =>
      getEventListByOrganization({ input: eventsArgs, pageParam }),
    {
      getNextPageParam: (page, pages) =>
        page.isNext ? pages.length : undefined,
      enabled: isSessionLoaded,
    },
  )

  return (
    <Stack spacing={{ xs: 2.25, sm: 3, xl: 4 }}>
      <MonthsTabs
        gradient={organizationHeaderGradient}
        months={months}
        selectedMonth={month}
        onSetMonth={setMonth}
      />
      <Stack
        spacing={{ xs: 2.25, lg: 3, xl: 4 }}
        minHeight={monthsContentMinHeight}
      >
        {$events.data?.pages !== undefined && (
          <Box>
            <Grid container spacing={eventCardWithoutTagsSpacing}>
              {$events.data.pages.map((group, idx) => (
                <Fragment key={idx}>
                  {group.rows.map((event) => (
                    <EventCardWithoutTags event={event} key={event.eventId} />
                  ))}
                </Fragment>
              ))}
            </Grid>
          </Box>
        )}
        {$events.hasNextPage && (
          <LoadingButton
            variant="secondary"
            size="medium"
            fullWidth
            onClick={() => $events.fetchNextPage()}
            loading={$events.isFetchingNextPage}
          >
            {t('load_more')}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  )
}
