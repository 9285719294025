
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { isSome } from 'fp-ts/lib/Option';
import { InferGetServerSidePropsType } from 'next';
import { getOrganization } from 'src/api';
import { ApiLocale } from 'src/app/locale';
import { validateUrlStringParam } from 'src/lib/validate-url-param';
import { OrganizationEventsPage, OrganizationEventsPageProps, } from 'src/modules/organization/events';
const getServerSideProps = validateUrlStringParam<OrganizationEventsPageProps>({
    paramName: 'organizationSlug',
    notFoundErrorCode: 'error_organization_not_found',
    onSuccess: async (organizationSlug) => {
        const organizationRequest = getOrganization({
            organizationSlug,
        });
        const [organization] = await Promise.all([organizationRequest]);
        return {
            props: {
                organizationInitialData: organization,
                disableNavigationBarGradient: isSome(organization.headerImage),
                locales: [ApiLocale.EN],
            },
        };
    },
});
const OrganizationEvents = ({ organizationInitialData, }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
    return (<OrganizationEventsPage organizationInitialData={organizationInitialData}/>);
};
export default OrganizationEvents;

    async function __Next_Translate__getServerSideProps__18d6016cc4f__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/organizations/[organizationSlug]/events',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__18d6016cc4f__ as getServerSideProps }
  