import { Box, Stack } from '@mui/material'
import { ResponsiveStyleValue } from '@mui/system'
import { ResponsiveImage, SrcSet } from '../responsive-image'

type Props = {
  src: string
  gradient: string
  containerProportion: ResponsiveStyleValue<number>
  imageProportion: ResponsiveStyleValue<number>
  backgroundColor?: string | null
  alt: string
  srcSet: SrcSet
}

export const GradientImage = ({
  src,
  gradient,
  containerProportion,
  imageProportion,
  backgroundColor,
  alt,
  srcSet,
}: Props) => {
  return (
    <Stack
      width={containerProportion}
      height={containerProportion}
      position="relative"
      justifyContent="center"
      alignItems="center"
      flexShrink={0}
    >
      <Box
        position="absolute"
        sx={{
          bottom: 0,
          top: 0,
          left: 0,
          right: 0,
          background: gradient,
          borderRadius: '50%',
        }}
      />
      <Box
        position="relative"
        width={imageProportion}
        height={imageProportion}
        sx={{
          '& .gradientImage': {
            borderRadius: '50%',
            backgroundColor: backgroundColor ?? 'common.white',
          },
        }}
      >
        <ResponsiveImage
          alt={alt}
          originalSizeUrl={src}
          className="gradientImage"
          srcSet={srcSet}
        />
      </Box>
    </Stack>
  )
}
