import { TypographyPropsVariantOverrides, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import parseHtml from 'html-react-parser'
import { forwardRef } from 'react'

type Props = {
  content: string
  typographyVariant?: keyof TypographyPropsVariantOverrides
}

export const RichTextOutput = forwardRef<HTMLInputElement | null, Props>(
  ({ content, typographyVariant }, ref) => {
    const theme = useTheme()

    return (
      <Box
        sx={{
          ...theme.typography[typographyVariant ?? 'bodyRegular'],
          '& ul,ol,h1,h2,h3,h4,h5,h6,p': {
            margin: 0,
          },
          '& p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          '& a': {
            color: '#1877F2',
            textDecoration: 'none',
          },
          '& a:hover': {
            borderBottom: '1px solid',
          },
          '& strong': {
            fontWeight: 600,
          },
          '& p:not(:last-child)': {
            marginBottom: { xs: 2.25, lg: 3 },
          },
          '& ul:not(:last-child)': { marginBottom: '12px' },
          '& ol:not(:last-child)': { marginBottom: '12px' },
          '& img': {
            maxWidth: '100%',
            height: '100%',
          },
        }}
        ref={ref}
      >
        {parseHtml(content)}
      </Box>
    )
  },
)
