import { Box, Stack, Typography } from '@mui/material'
import { EventIcon } from './icons/event'
import useTranslation from 'next-translate/useTranslation'

export const EventsEmptyState = () => {
  const { t } = useTranslation()

  return (
    <Stack
      sx={{
        height: { xs: 174, sm: 152, lg: 160, xl: 164 },
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg' %3E%3Cdefs%3E%3ClinearGradient id='gradient' x1='0%25' y1='0%25' x2='100%25' y2='0%25'%3E%3Cstop stop-color='%23FC605B' /%3E%3Cstop offset='0.510417' stop-color='%239E7BFF' /%3E%3Cstop offset='1' stop-color='%234AC398' /%3E%3C/linearGradient%3E%3C/defs%3E%3Crect width='100%25' height='100%25' fill='white' rx='12' ry='12' stroke='url(%23gradient)' stroke-width='2' stroke-dasharray='3 7' stroke-dashoffset='0' stroke-linecap='square' /%3E%3C/svg%3E")`,
        borderRadius: '12px',
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        px={{ xs: 3, sm: 4 }}
        spacing={{ xs: 1.5, lg: 2 }}
      >
        <Box sx={{ flexShrink: 0, lineHeight: 0 }}>
          <EventIcon />
        </Box>
        <Typography
          variant="h4"
          textAlign="center"
          sx={{ maxWidth: { lg: 546, xl: 606 } }}
        >
          {t('no_upcoming_events')}
        </Typography>
      </Stack>
    </Stack>
  )
}
