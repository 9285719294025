import { useQuery } from 'react-query'
import { Stack, Typography } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { NextSeo } from 'next-seo'
import { isSome } from 'fp-ts/lib/Option'
import { getOrganizationMonths, IOrganization, qk } from 'src/api'
import { useLocale } from 'src/lib/use-locale'
import { formatCityCountry } from 'src/lib/format'
import { getSeoDescription } from 'src/components/rich-text-output'
import { getAbsoluteUrlWithLocale } from 'src/lib/get-path-with-locale'
import { pages } from 'src/app/pages'
import { OrganizationLayout, OrganizationTab } from 'src/layouts/organization'
import { OrganizationEvents } from './organization-events'
import { isNonEmpty } from 'fp-ts/lib/Array'
import { EventsEmptyState } from 'src/components/events-empty-state'

export type OrganizationEventsPageProps = {
  organizationInitialData: IOrganization
}

export const OrganizationEventsPage = ({
  organizationInitialData,
}: OrganizationEventsPageProps) => {
  const { t } = useTranslation()
  const { locale } = useLocale()

  const $months = useQuery(
    qk.cms.public.event.organizationMonths.toKeyWithArgs({
      organizationSlug: organizationInitialData.organizationSlug,
    }),
    () =>
      getOrganizationMonths({
        organizationSlug: organizationInitialData.organizationSlug,
      }),
  )

  return (
    <>
      <NextSeo
        title={`${t('events_by')} ${
          organizationInitialData.name
        } // ${formatCityCountry({
          city: organizationInitialData.city,
          country: organizationInitialData.country,
        })}`}
        description={getSeoDescription(organizationInitialData.description)}
        openGraph={{
          type: 'website',
          url: getAbsoluteUrlWithLocale({
            locale,
            path: pages['/organizations/[organizationSlug]/events']({
              organizationSlug: organizationInitialData.organizationSlug,
            }),
          }),
          images: isSome(organizationInitialData.headerImage)
            ? [{ url: organizationInitialData.headerImage.value.url }]
            : undefined,
        }}
      />
      <OrganizationLayout
        organizationInitialData={organizationInitialData}
        tab={OrganizationTab.EVENTS}
      >
        <Stack spacing={{ xs: 2.25, sm: 2.5, lg: 3, xl: 4 }}>
          <Typography variant="h2Black" component="h3">
            {t('all_events')}
          </Typography>
          {$months.isSuccess &&
            (isNonEmpty($months.data) ? (
              <OrganizationEvents
                organizationSlug={organizationInitialData.organizationSlug}
                months={$months.data}
              />
            ) : (
              <EventsEmptyState />
            ))}
        </Stack>
      </OrganizationLayout>
    </>
  )
}
