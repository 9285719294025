import { Parser } from 'htmlparser2'

export const getHtmlText = (input: string) => {
  const parsedText: Array<string> = []
  const parser = new Parser({
    ontext: (text) => {
      // eslint-disable-next-line fp/no-mutating-methods
      parsedText.push(text)
    },
  })
  parser.write(input)
  return parsedText.join('')
}

export const getSeoDescription = (input: string, limit = 100) => {
  const text = getHtmlText(input)

  if (text.length > limit) {
    const trimmedText = text.slice(0, limit)
    return `${trimmedText}...`
  }

  return text
}
