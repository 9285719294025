export const EventIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path
        d="M40.5359 7.92303H38.4689V6.54504C38.4689 5.02512 37.2329 3.78906 35.7129 3.78906C34.193 3.78906 32.957 5.02512 32.957 6.54504V7.92303H18.4881C18.1077 7.92303 17.7991 8.2317 17.7991 8.61203C17.7991 8.99235 18.1077 9.30102 18.4881 9.30102H32.957V10.679C32.957 12.1989 34.193 13.435 35.7129 13.435C37.2329 13.435 38.4689 12.1989 38.4689 10.679V9.30102H40.5359C43.1947 9.30102 45.3589 11.4645 45.3589 14.124V14.813H25.378C24.997 14.813 24.689 15.1217 24.689 15.502C24.689 15.8823 24.997 16.191 25.378 16.191H45.3589V36.1718C45.3589 38.8314 43.1947 40.9948 40.5359 40.9948H7.46414C4.80462 40.9948 2.64117 38.8314 2.64117 36.1718V16.191H5.39715C5.77748 16.191 6.08615 15.8823 6.08615 15.502C6.08615 15.1217 5.77748 14.813 5.39715 14.813H2.64117V14.124C2.64117 11.4645 4.80462 9.30102 7.46414 9.30102H10.2201C10.6004 9.30102 10.9091 8.99235 10.9091 8.61203C10.9091 8.2317 10.6004 7.92303 10.2201 7.92303H7.46414C4.04535 7.92303 1.26318 10.7052 1.26318 14.124V36.1718C1.26318 39.5906 4.04535 42.3728 7.46414 42.3728H40.5359C43.9554 42.3728 46.7369 39.5906 46.7369 36.1718V14.124C46.7369 10.7052 43.9554 7.92303 40.5359 7.92303ZM37.0909 10.679C37.0909 11.439 36.4729 12.057 35.7129 12.057C34.953 12.057 34.335 11.439 34.335 10.679V6.54504C34.335 5.78508 34.953 5.16705 35.7129 5.16705C36.4729 5.16705 37.0909 5.78508 37.0909 6.54504V10.679Z"
        fill="black"
        stroke="black"
        strokeWidth="0.378947"
      />
      <path
        d="M6.25646 12.6956C6.09317 13.1978 6.22684 13.7394 6.60509 14.108L9.54159 16.9708L8.84846 21.0124C8.75958 21.5333 8.96904 22.0494 9.3969 22.3601C9.82477 22.6715 10.3808 22.7108 10.8479 22.4648L14.4782 20.5563L18.1079 22.4648C18.3111 22.5716 18.5316 22.6247 18.7507 22.6247C19.0353 22.6247 19.3177 22.5358 19.5596 22.3601C19.9874 22.0494 20.1969 21.5333 20.108 21.0124L19.4142 16.9708L22.3514 14.108C22.7296 13.7394 22.8633 13.1978 22.6993 12.6956C22.536 12.1933 22.1102 11.8343 21.5873 11.7578L17.5291 11.1687L15.7143 7.49089C15.4807 7.01686 15.0067 6.72266 14.4789 6.72266C13.9505 6.72266 13.4764 7.01686 13.2429 7.49089L11.4281 11.1681L7.36988 11.7578C6.84624 11.8336 6.41975 12.1933 6.25646 12.6956ZM11.6258 12.5316C12.0743 12.4661 12.4622 12.1843 12.6634 11.7778L14.4755 8.09582L14.4776 8.09996L16.2924 11.7778C16.4936 12.1843 16.8808 12.4668 17.33 12.5316L21.3889 13.1207L18.4517 15.9834C18.1272 16.2997 17.979 16.7558 18.0555 17.203L18.7486 21.2446L15.119 19.3361C14.7173 19.1246 14.2378 19.1246 13.8368 19.3361L10.2065 21.2446L10.8996 17.203C10.9761 16.7558 10.8279 16.2997 10.5034 15.9828L7.56693 13.1207L11.6258 12.5316Z"
        fill="url(#paint0_linear_9543_113977)"
        stroke="url(#paint1_linear_9543_113977)"
        strokeWidth="0.378947"
      />
      <path
        d="M26.7567 32.0374C26.8972 32.0374 27.0385 31.9947 27.1611 31.9058C27.4691 31.6819 27.5373 31.2513 27.3134 30.9433L21.8014 23.3643C21.5768 23.0564 21.1469 22.9875 20.8389 23.2128C20.5316 23.4367 20.4634 23.8673 20.6873 24.1753L26.1993 31.7542C26.3336 31.9389 26.5431 32.0374 26.7567 32.0374Z"
        fill="black"
        stroke="black"
        strokeWidth="0.378947"
      />
      <path
        d="M27.3919 25.5711L23.947 17.3032C23.8009 16.9525 23.3985 16.7851 23.0458 16.9325C22.6944 17.0786 22.529 17.4823 22.6751 17.8337L26.12 26.1017C26.2303 26.3662 26.4859 26.5254 26.756 26.5254C26.8449 26.5254 26.9344 26.5082 27.0213 26.4717C27.3726 26.3256 27.5387 25.9225 27.3919 25.5711Z"
        fill="black"
        stroke="black"
        strokeWidth="0.378947"
      />
      <path
        d="M21.2434 31.3493C21.4619 31.3493 21.6768 31.2459 21.8105 31.0523C22.0268 30.7395 21.949 30.3103 21.6362 30.0932L12.6792 23.8923C12.3657 23.6752 11.9372 23.7538 11.7202 24.0666C11.5031 24.3794 11.5817 24.8086 11.8945 25.0257L20.8514 31.2266C20.9713 31.3093 21.1084 31.3493 21.2434 31.3493Z"
        fill="black"
        stroke="black"
        strokeWidth="0.378947"
      />
      <path
        d="M34.335 19.6367C33.954 19.6367 33.646 19.9454 33.646 20.3257V21.0147C33.646 21.395 33.954 21.7037 34.335 21.7037C34.716 21.7037 35.024 21.395 35.024 21.0147V20.3257C35.024 19.9454 34.716 19.6367 34.335 19.6367Z"
        fill="black"
        stroke="black"
        stroke-width="0.378947"
      />
      <path
        d="M32.957 21.7031H32.268C31.887 21.7031 31.579 22.0118 31.579 22.3921C31.579 22.7724 31.887 23.0811 32.268 23.0811H32.957C33.338 23.0811 33.646 22.7724 33.646 22.3921C33.646 22.0118 33.338 21.7031 32.957 21.7031Z"
        fill="black"
        stroke="black"
        strokeWidth="0.378947"
      />
      <path
        d="M36.4019 21.7031H35.7129C35.3319 21.7031 35.0239 22.0118 35.0239 22.3921C35.0239 22.7724 35.3319 23.0811 35.7129 23.0811H36.4019C36.7829 23.0811 37.0909 22.7724 37.0909 22.3921C37.0909 22.0118 36.7829 21.7031 36.4019 21.7031Z"
        fill="black"
        stroke="black"
        strokeWidth="0.378947"
      />
      <path
        d="M33.646 24.4581C33.646 24.8384 33.954 25.1471 34.335 25.1471C34.716 25.1471 35.024 24.8384 35.024 24.4581V23.7691C35.024 23.3887 34.716 23.0801 34.335 23.0801C33.954 23.0801 33.646 23.3887 33.646 23.7691V24.4581Z"
        fill="black"
        stroke="black"
        strokeWidth="0.378947"
      />
      <path
        d="M30.89 33.4136V34.1026C30.89 34.4829 31.198 34.7916 31.579 34.7916C31.96 34.7916 32.268 34.4829 32.268 34.1026V33.4136C32.268 33.0333 31.96 32.7246 31.579 32.7246C31.198 32.7246 30.89 33.0333 30.89 33.4136Z"
        fill="black"
        stroke="black"
        strokeWidth="0.378947"
      />
      <path
        d="M30.2009 34.7891H29.5119C29.1309 34.7891 28.8229 35.0977 28.8229 35.4781C28.8229 35.8584 29.1309 36.1671 29.5119 36.1671H30.2009C30.5819 36.1671 30.8899 35.8584 30.8899 35.4781C30.8899 35.0977 30.5819 34.7891 30.2009 34.7891Z"
        fill="black"
        stroke="black"
        strokeWidth="0.378947"
      />
      <path
        d="M32.9571 34.7891C32.576 34.7891 32.2681 35.0977 32.2681 35.4781C32.2681 35.8584 32.576 36.1671 32.9571 36.1671H33.6461C34.0271 36.1671 34.3351 35.8584 34.3351 35.4781C34.3351 35.0977 34.0271 34.7891 33.6461 34.7891H32.9571Z"
        fill="black"
        stroke="black"
        strokeWidth="0.378947"
      />
      <path
        d="M30.89 37.5499C30.89 37.9302 31.198 38.2389 31.579 38.2389C31.96 38.2389 32.268 37.9302 32.268 37.5499V36.8609C32.268 36.4805 31.96 36.1719 31.579 36.1719C31.198 36.1719 30.89 36.4805 30.89 36.8609V37.5499Z"
        fill="black"
        stroke="black"
        strokeWidth="0.378947"
      />
      <path
        d="M7.46411 29.2818C7.46411 30.4214 8.3915 31.3488 9.5311 31.3488C10.6707 31.3488 11.5981 30.4214 11.5981 29.2818C11.5981 28.1422 10.6707 27.2148 9.5311 27.2148C8.3915 27.2148 7.46411 28.1422 7.46411 29.2818ZM10.2201 29.2818C10.2201 29.6615 9.91073 29.9708 9.5311 29.9708C9.15146 29.9708 8.8421 29.6615 8.8421 29.2818C8.8421 28.9022 9.15146 28.5928 9.5311 28.5928C9.91073 28.5928 10.2201 28.9022 10.2201 29.2818Z"
        fill="black"
        stroke="black"
        strokeWidth="0.378947"
      />
      <path
        d="M12.9762 35.481C12.9762 36.6206 13.9036 37.548 15.0432 37.548C16.1828 37.548 17.1102 36.6206 17.1102 35.481C17.1102 34.3414 16.1828 33.4141 15.0432 33.4141C13.9036 33.4141 12.9762 34.3414 12.9762 35.481ZM15.7322 35.481C15.7322 35.8607 15.4228 36.17 15.0432 36.17C14.6635 36.17 14.3542 35.8607 14.3542 35.481C14.3542 35.1014 14.6635 34.7921 15.0432 34.7921C15.4228 34.7921 15.7322 35.1014 15.7322 35.481Z"
        fill="black"
        stroke="black"
        strokeWidth="0.378947"
      />
      <path
        d="M38.469 28.5898C38.0879 28.5898 37.78 28.8985 37.78 29.2788V29.9678H37.091C36.71 29.9678 36.402 30.2765 36.402 30.6568C36.402 31.0372 36.71 31.3458 37.091 31.3458H37.78V32.0348C37.78 32.4151 38.0879 32.7238 38.469 32.7238C38.85 32.7238 39.158 32.4151 39.158 32.0348V31.3458H39.847C40.228 31.3458 40.5359 31.0372 40.5359 30.6568C40.5359 30.2765 40.228 29.9678 39.847 29.9678H39.158V29.2788C39.158 28.8985 38.85 28.5898 38.469 28.5898Z"
        fill="#1D1D1D"
        stroke="#1D1D1D"
        strokeWidth="0.378947"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9543_113977"
          x1="0.707294"
          y1="15.9081"
          x2="24.0804"
          y2="17.2075"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FC605B" />
          <stop offset="0.510417" stop-color="#9E7BFF" />
          <stop offset="1" stop-color="#4AC398" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9543_113977"
          x1="0.707294"
          y1="15.9081"
          x2="24.0804"
          y2="17.2075"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FC605B" />
          <stop offset="0.510417" stop-color="#9E7BFF" />
          <stop offset="1" stop-color="#4AC398" />
        </linearGradient>
      </defs>
    </svg>
  )
}
