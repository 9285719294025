import { MouseEvent, useRef } from 'react'
import { Box, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { useWindowSize } from 'react-use'
import { IMonth, IMonths } from 'src/api'
import { scrollableRowStyles } from 'src/lib/scrollable-row-styles'

export const monthsContentMinHeight = { xs: 157, sm: 309, lg: 360, xl: 414 }

type Props = {
  months: IMonths
  selectedMonth: IMonth
  onSetMonth: (month: IMonth) => void
  gradient: string
}

export const MonthsTabs = ({
  months,
  onSetMonth,
  selectedMonth,
  gradient,
}: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const containerRef = useRef<HTMLDivElement>(null)
  const screenWidth = useWindowSize()

  const handleChange = (_event: MouseEvent<HTMLElement>, newMonth: IMonth) => {
    if (newMonth !== null) {
      onSetMonth(newMonth)
    }
  }

  return (
    <Box ref={containerRef}>
      {containerRef.current !== null && (
        <Box
          sx={{
            ...scrollableRowStyles,
            width: '100vw',
            ml: { md: 'calc((100% - 100vw) / 2)' },
            px: {
              md: `${
                (screenWidth.width - containerRef.current.clientWidth) / 2
              }px`,
            },
          }}
        >
          <ToggleButtonGroup
            value={selectedMonth}
            exclusive
            onChange={handleChange}
            sx={{
              '&&': {
                '& .MuiToggleButtonGroup-grouped': {
                  ...theme.typography.h4,
                  border: 'none',
                  borderRadius: 0,
                  boxShadow: 'unset',
                  pt: 0,
                  pb: { xs: 1.5 },
                  px: { xs: 0.5, lg: 1 },
                  color: 'secondary8.main',
                  flexBasis: 'auto',
                  flexGrow: 0,
                  backgroundColor: 'transparent',
                  position: 'relative',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                  '&::after': {
                    position: 'absolute',
                    content: '""',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: 2,
                    backgroundColor: 'transparent',
                  },
                },
                '& .MuiToggleButtonGroup-grouped.Mui-selected': {
                  color: 'common.black',
                  borderColor: 'transparent',
                  '&::after': {
                    background: gradient,
                  },
                },
                '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
                  marginLeft: { xs: 2.75 },
                },
              },
            }}
          >
            {months.map((month) => (
              <ToggleButton key={month} value={month} disableRipple>
                {t(`months_${month}`)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
      )}
    </Box>
  )
}
